import { useActiveMeetings, useOrganization, useUser } from '@groupthinkai/groupthink';
import {
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  Chat as ChatIcon,
  Description as DescriptionIcon,
  Event as EventIcon,
  LiveHelpOutlined as LiveHelpOutlinedIcon,
  ManageAccounts as ManageAccountsIcon,
  ReadMore as ReadMoreIcon,
  RoomPreferences as RoomPreferencesIcon,
  SmartToy as SmartToyIcon,
  SportsScore as SportsScoreIcon,
  TaskAlt as TaskAltIcon,
  ThumbUpAltOutlined as ThumbUpAltOutlinedIcon,
} from '@mui/icons-material';
import { Avatar, Box, Divider, Link, Stack, useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import CreateMeeting from '@/components/CreateMeeting';
import UserMenu from '@/components/header/UserMenu';
import SectionDivider from '@/components/navigation/SectionDivider';
import NavigationLink from '@/components/navigation/trees/NavigationLink';
import RoomTree from '@/components/navigation/trees/RoomTree';
import { useCurrentOrganization } from '@/hooks/organization';
import { useChromelessLayout } from '@/hooks/util';

export default function NextNavigation({ showLabels = true, showNav = true }) {
  const router = useRouter();
  const { user } = useUser('me');

  const { isChromeless } = useChromelessLayout();
  const theme = useTheme();
  const lgPlus = useMediaQuery(theme.breakpoints.up('lg'));

  const [activeLink, setActiveLink] = React.useState('');

  const { activeMeetings } = useActiveMeetings();
  const hasActiveMeeting = activeMeetings?.length > 0;

  const [currentOrganization] = useCurrentOrganization();
  const { organization } = useOrganization(currentOrganization);

  React.useEffect(() => {
    setActiveLink(router.asPath);
  }, [router.asPath]);

  if (isChromeless) {
    return null;
  }

  let topSideBarIcons = [
    {
      title: 'Next Up',
      icon: <ReadMoreIcon sx={{ fontSize: '1.25rem' }} />,
      link: '/dashboard',
    },
    {
      title: 'Tasks',
      icon: <TaskAltIcon sx={{ fontSize: '1.25rem' }} />,
      link: '/tasks',
    },
    {
      title: 'Calendar',
      icon: <CalendarMonthOutlinedIcon sx={{ fontSize: '1.25rem' }} />,
      link: '/calendar',
    },
    ...(organization?.features?.groupthink_objectives
      ? [
          {
            title: 'Objectives',
            icon: <SportsScoreIcon sx={{ fontSize: '1.25rem' }} />,
            link: '/objectives',
          },
        ]
      : []),
  ];

  let documentSidebarIcons = [
    {
      title: 'AI Employees',
      icon: <SmartToyIcon sx={{ fontSize: '1.25rem' }} />,
      link: '/assistants',
    },
  ];

  if (user?.user_name) {
    documentSidebarIcons.unshift({
      title: 'Contributions',
      icon: <ThumbUpAltOutlinedIcon sx={{ fontSize: '1.25rem' }} />,
      link: '/u/' + user?.user_name,
    });
  }

  let bottomSideBarIcons = [
    {
      title: 'Email Support',
      icon: <LiveHelpOutlinedIcon sx={{ fontSize: '1.25rem' }} />,
      link: 'mailto:support@groupthink.com',
      target: '_blank',
    },
    {
      title: 'User Settings',
      icon: <ManageAccountsIcon sx={{ fontSize: '1.25rem' }} />,
      link: `/settings`,
    },
  ];

  if (currentOrganization) {
    bottomSideBarIcons.push({
      title: 'Organization Settings',
      icon: <RoomPreferencesIcon sx={{ fontSize: '1.25rem' }} />,
      link: `/organizations/${currentOrganization}/settings`,
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'space-between',
        pt: '8px',
        paddingBottom: '8px',
        overflow: 'scroll',
        backgroundColor: 'groupthinkNeutral.200',
        height: '100%',
        '& .navigation-label': { opacity: showLabels ? '1' : '0' },
        '&:hover': {
          '& .navigation-label': { opacity: '1' },
        },
        WebkitUserSelect: 'none', // Prevent text selection in navigation sidebar
      }}>
      <Stack
        className="primary-navigation"
        direction="column"
        sx={{
          alignItems: 'flex-start',
          gap: '1rem',
          overflow: 'auto',
          px: 1,
          pb: '180px',
        }}>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}>
          {lgPlus && (
            <Link component={NextLink} href="/dashboard">
              <Avatar
                src={`/gt_primary.svg`}
                srcSet={`/gt_primary.svg`}
                sx={{
                  borderRadius: '0',
                  backgroundColor: 'transparent',
                  opacity: 1,
                  ml: 0.5,
                  width: '32px',
                  height: '32px',
                }}
              />
            </Link>
          )}
          {!hasActiveMeeting && <CreateMeeting />}
        </Stack>

        {showNav && (
          <Stack
            sx={{
              width: '100%',
            }}>
            {topSideBarIcons.map((icon, index) => {
              const link = (
                <NavigationLink
                  key={index}
                  href={icon.link}
                  label={icon.title}
                  labelDecorator={icon.icon}
                  active={activeLink === icon.link}
                />
              );
              return link;
            })}

            <SectionDivider label="Work" />
            <NavigationLink
              href="/agendas"
              label="Meetings"
              labelDecorator={<EventIcon sx={{ fontSize: '1.25rem' }} />}
              active={activeLink === '/agendas'}
            />
            <NavigationLink
              href="/documents"
              label="Documents"
              labelDecorator={<DescriptionIcon sx={{ fontSize: '1.25rem' }} />}
              active={activeLink === '/documents'}
            />
            {documentSidebarIcons.map((icon, index) => {
              return (
                <NavigationLink
                  key={index}
                  href={icon.link}
                  label={icon.title}
                  labelDecorator={icon.icon}
                  active={activeLink === icon.link}
                />
              );
            })}

            <RoomTree activeLink={activeLink} />
          </Stack>
        )}
      </Stack>
      <Stack
        className="secondary-navigation"
        direction="column"
        sx={{
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'groupthinkNeutral.200',
          width: { xs: '144px', lg: '224px' }, // keep in sync with GRID_COLUMN_WIDTH_NAV; 'inherit' is not sufficient here
          px: 1,
          pb: 1,
        }}>
        <Divider sx={{ mb: 1 }} />
        <UserMenu showName={true} autoLogin={true} placement={'top-start'} />
      </Stack>
    </Box>
  );
}
