import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';
dayjs.extend(duration);

const WARN_BEFORE_MEETING_ENDS_IN_MINUTES = 5;

export default function MeetingTimer({ endTime = '', startTime = '', verbose = true }) {
  const [timeLabel, setTimeLabel] = useState('Time:');
  const [timeSuffix, setTimeSuffix] = useState('');
  const [timeDuration, setTimeDuration] = useState(null);
  const [overTime, setOverTime] = useState(false);
  const [meetingEndingWarning, setMeetingEndingWarning] = useState(false);

  const updateTimer = () => {
    const currentTime = dayjs();
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    const differenceInStartTime = start.diff(currentTime, 'second');
    const differenceInEndTime = end.diff(currentTime, 'second');

    let min = 0;

    if (!endTime) {
      setTimeLabel('');
      setTimeSuffix('elapsed');
      setMeetingEndingWarning(false);
      setOverTime(false);
      setTimeDuration(differenceInStartTime);
    } else if (differenceInStartTime > 0) {
      setTimeLabel('Starts in ');
      setTimeSuffix('');
      setMeetingEndingWarning(false);
      setTimeDuration(differenceInStartTime);
    } else if (differenceInEndTime >= 0) {
      min = Math.floor(differenceInEndTime / 60);
      setMeetingEndingWarning(min < WARN_BEFORE_MEETING_ENDS_IN_MINUTES);
      setOverTime(false);
      setTimeLabel('');
      setTimeSuffix('remaining');
      setTimeDuration(differenceInEndTime);
    } else {
      setOverTime(true);
      setTimeLabel('');
      setTimeSuffix('over');
      setTimeDuration(differenceInEndTime);
    }
  };

  // Render initial timestamp, then re-calculate timestamps every second
  useEffect(() => {
    updateTimer();
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, [endTime, startTime]);

  const formatNumber = (num) => {
    return Math.abs(num).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  const formatElapsedTime = () => {
    const duration = dayjs.duration(Math.abs(timeDuration), 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours > 0) {
      return (
        <>
          <span className="hours">{hours}</span>
          <span className="divider">:</span>
          <span className="minutes">{formatNumber(minutes)}</span>
          <span className="divider">:</span>
          <span className="seconds">{formatNumber(seconds)}</span>
        </>
      );
    } else {
      return (
        <>
          <span className="minutes">{formatNumber(minutes)}</span>
          <span className="divider">:</span>
          <span className="seconds">{formatNumber(seconds)}</span>
        </>
      );
    }
  };

  let color = '#1F97AC';

  if (overTime) {
    color = 'red';
  } else if (meetingEndingWarning) {
    color = '#F4BF4F';
  }

  if (timeDuration === 0) {
    return null;
  }

  return (
    <span style={{ color }}>
      {verbose && timeLabel}
      <span
        style={{
          color,
          fontWeight: 'bold',
          marginRight: '0.25rem',
          fontFamily: 'monospace',
        }}>
        {formatElapsedTime()}
      </span>
      {verbose && timeSuffix}
    </span>
  );
}
