import { createAgenda, useMeetings, useOrganization } from '@groupthinkai/groupthink';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Card,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
  Divider,
  Button,
} from '@mui/joy';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect } from 'react';

import StartMeetingModal from '@/components/agenda/StartMeetingModal';
import ScheduleMeetingModal from '@/components/calendar/ScheduleMeetingModal';
import useDataLayer from '@/hooks/dataLayer';
import { useCurrentOrganization } from '@/hooks/organization';

export default function CreateMeeting({
  type = 'button',
  direction = 'row',
  focused = false,
  onExisting = () => {},
  onSchedule = () => {},
  onNew = () => {},
}) {
  const router = useRouter();
  const [currentOrganization] = useCurrentOrganization();
  const { organization } = useOrganization(currentOrganization);
  const [isCreating, setIsCreating] = React.useState(false);
  const [agendaId, setAgendaId] = React.useState(null);
  const { createMeeting } = useMeetings(agendaId);

  const { pushData } = useDataLayer();

  const [openStartMeeting, setOpenStartMeeting] = React.useState(false);
  const [openScheduleMeeting, setOpenScheduleMeeting] = React.useState(false);
  const [conferenceUrl, setConferenceUrl] = React.useState(null);

  useEffect(() => {
    if (agendaId) {
      let payload = { start: true, workspace_id: currentOrganization };

      if (conferenceUrl) {
        payload.conference_url = conferenceUrl;
        payload.source = 'create-meeting-button-existing';
      } else {
        payload.source = 'create-meeting-button-new';
      }

      createMeeting({
        payload: payload,
        setIsCreating,
        onSuccess: () => {
          setOpenStartMeeting(false);
          setConferenceUrl(null);

          router.push(`/agendas/${agendaId}`);
        },
      });
    }
  }, [agendaId]);

  const handleExisting = () => {
    onExisting();
    pushData('Button Clicked', {
      workspace_id: currentOrganization,
      button_name: 'Add to Existing Meeting',
      button_intent: 'create meeting',
      button_context: 'existing meeting',
    });
    if (!organization?.calendar_id) {
      router.push('/calendar?connect=true');
    } else {
      setOpenStartMeeting(true);
    }
  };

  const handleNew = async () => {
    onNew();
    pushData('Button Clicked', {
      workspace_id: currentOrganization,
      button_name: 'Start a New Meeting',
      button_intent: 'create meeting',
      button_context: 'new meeting',
    });

    await createAgenda({
      payload: { name: 'New Meeting', workspace_id: currentOrganization, visibility: 'workspace' },
      setIsCreating,
      onSuccess: (agenda) => {
        setAgendaId(agenda.id);
      },
    });
  };

  const handleScheduled = () => {
    onSchedule();
    pushData('Button Clicked', {
      workspace_id: currentOrganization,
      button_name: 'Schedule a Meeting',
      button_intent: 'create meeting',
      button_context: 'schedule meeting',
    });
    if (!organization?.calendar_id) {
      router.push('/calendar?connect=true');
    } else {
      setOpenScheduleMeeting(true);
    }
  };

  const newMeetingContent = (
    <Stack>
      <Typography level="body-md" fontWeight="lg" gutterBottom>
        Start a New Meeting
      </Typography>
      <Typography level="body-sm">
        Creates a new Groupthink video meeting and adds the Groupthink meeting assistant
      </Typography>
    </Stack>
  );

  const existingMeetingContent = (
    <Stack>
      <Typography level="body-md" fontWeight="lg" gutterBottom>
        Add Groupthink to Current Meeting...
      </Typography>
      <Typography level="body-sm">
        Groupthink will join your active Zoom, Meet, or Teams meeting to take notes and manage the
        agenda
      </Typography>
    </Stack>
  );

  const scheduledMeetingContent = (
    <Stack>
      <Typography level="body-md" fontWeight="lg" gutterBottom>
        Schedule a Meeting...
      </Typography>
      <Typography level="body-sm">
        Create a new meeting on your calendar with Groupthink included for video &amp; notes
      </Typography>
    </Stack>
  );

  const startMeetingModal = (
    <StartMeetingModal
      open={openStartMeeting}
      setOpen={setOpenStartMeeting}
      conferenceUrl={conferenceUrl}
      setConferenceUrl={setConferenceUrl}
      isCreating={isCreating}
      onStartMeeting={handleNew}
      groupthinkVideoEnabled={false}
    />
  );

  const scheduleMeetingModal = (
    <ScheduleMeetingModal open={openScheduleMeeting} setOpen={setOpenScheduleMeeting} />
  );

  if (type === 'button') {
    return (
      <Dropdown>
        <MenuButton
          variant="solid"
          color="primary"
          size="sm"
          loading={isCreating}
          disabled={isCreating}
          onClick={() => {
            pushData('Button Clicked', {
              workspace_id: currentOrganization,
              button_name: 'Create Meeting',
              button_intent: 'create meeting',
              button_context: 'meeting type selection',
            });
          }}
          startDecorator={<AddIcon sx={{ fontSize: '1.25rem' }} />}
          endDecorator={<ArrowDropDown sx={{ fontSize: '1.25rem' }} />}>
          Meeting
        </MenuButton>
        <Menu
          placement={'bottom-start'}
          size="lg"
          sx={{
            width: '20rem',
          }}>
          <MenuItem onClick={handleNew}>{newMeetingContent}</MenuItem>
          <MenuItem onClick={handleScheduled}>{scheduledMeetingContent}</MenuItem>
          <MenuItem onClick={handleExisting}>{existingMeetingContent}</MenuItem>
        </Menu>
        {startMeetingModal}
        {scheduleMeetingModal}
      </Dropdown>
    );
  }

  if (type === 'icon') {
    return (
      <Dropdown>
        <MenuButton
          variant="solid"
          color="primary"
          loading={isCreating}
          disabled={isCreating}
          sx={{
            textTransform: 'uppercase',
            borderRadius: '50%',
            height: '2.5rem',
            width: '2.5rem',
          }}>
          <AddIcon />
        </MenuButton>
        <Menu
          size="lg"
          sx={{
            width: '20rem',
            zIndex: 10000,
          }}>
          <MenuItem onClick={handleNew}>{newMeetingContent}</MenuItem>
          <MenuItem onClick={handleScheduled}>{scheduledMeetingContent}</MenuItem>
          <MenuItem onClick={handleExisting}>{existingMeetingContent}</MenuItem>
        </Menu>
        {startMeetingModal}
        {scheduleMeetingModal}
      </Dropdown>
    );
  }

  return (
    <Stack direction={direction} spacing={2}>
      <Card
        sx={{
          flex: 1,
          alignItems: 'baseline',
          p: 2,
          backgroundColor: 'white',
          borderWidth: 2,
        }}
        variant={'outlined'}>
        {newMeetingContent}
        <Divider />
        <Button
          onClick={handleNew}
          loading={isCreating}
          disabled={isCreating}
          variant={focused ? 'solid' : 'soft'}
          color="primary">
          Start
        </Button>
      </Card>
      <Card
        sx={{
          flex: 1,
          alignItems: 'baseline',
          p: 2,
          backgroundColor: 'white',
          borderWidth: 2,
        }}
        variant={'outlined'}>
        {scheduledMeetingContent}
        <Divider />
        <Button
          onClick={handleScheduled}
          loading={isCreating}
          disabled={isCreating}
          variant={focused ? 'solid' : 'soft'}
          color="primary">
          Schedule...
        </Button>
      </Card>

      <Card
        sx={{
          flex: 1,
          p: 2,
          alignItems: 'baseline',
          backgroundColor: 'white',
          borderWidth: 2,
        }}
        variant={'outlined'}>
        {existingMeetingContent}
        <Divider />
        <Button onClick={handleExisting} variant={focused ? 'solid' : 'soft'} color="primary">
          Invite...
        </Button>
      </Card>
      {startMeetingModal}
      {scheduleMeetingModal}
    </Stack>
  );
}
