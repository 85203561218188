import { useAgenda, useUserAgendas } from '@groupthinkai/groupthink';
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete';
import CircularProgress from '@mui/joy/CircularProgress';
import React, { useState, useEffect } from 'react';

import AgendaCard from './AgendaCard';

interface AgendaSelectProps {
  value: string | null;
  onChange: (newValue: string | null) => void;
}

const filter = createFilterOptions<Groupthink.AgendaResource>();

export default function AgendaSelect({ value, onChange }: AgendaSelectProps) {
  const [inputValue, setInputValue] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useState('');
  const { agendas, isLoading } = useUserAgendas(debouncedSearchString);
  const { agenda } = useAgenda(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchString(inputValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [inputValue]);

  const selectedAgenda = agendas?.find((option) => option.id === value) || agenda;

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Groupthink.AgendaResource
  ) => {
    return (
      <li {...props} key={option.id}>
        <AgendaCard
          link={false}
          agenda={agendas.find((agenda) => agenda.id === option.id) || agenda}
        />
      </li>
    );
  };

  return (
    <Autocomplete<Groupthink.AgendaResource, false, false, true>
      freeSolo
      placeholder="New agenda"
      // @ts-ignore
      value={selectedAgenda}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        if (newValue && typeof newValue === 'object') {
          onChange(newValue.id);
        } else {
          onChange(null);
        }
      }}
      onBlur={() => {
        if (!selectedAgenda) {
          setInputValue('');
          onChange(null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={renderOption}
      // @ts-ignore
      options={agendas || []}
      loading={isLoading}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      slotProps={{
        input: {
          endDecorator: isLoading ? <CircularProgress size="sm" /> : null,
        },
      }}
    />
  );
}
