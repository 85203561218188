import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip, Divider, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import * as React from 'react';

export default function SectionMenu({ title, children }) {
  return (
    <Divider
      sx={{
        '--Divider-childPosition': '0px',
        '--Divider-gap': '0px',
        mt: 0,
        ml: 0,
        mr: 0,
        fontSize: '12px',
        color: 'neutral.900',
      }}>
      <Dropdown>
        <MenuButton
          endDecorator={<AddIcon sx={{ fontSize: '16px' }} />}
          slotProps={{
            endDecorator: {
              sx: {
                marginLeft: '2px',
              },
            },
          }}
          sx={{
            color: 'neutral.500',
            border: 'none',
            padding: 0,
            margin: 0,
            px: 0.5,
            py: 0.5,
            fontSize: '12px',
            lineHeight: '14px',
            minHeight: 0,
          }}>
          {title}
        </MenuButton>
        <Menu size="sm">{children}</Menu>
      </Dropdown>
    </Divider>
  );
}
