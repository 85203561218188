import { useRooms } from '@groupthinkai/groupthink';
import { Tag as TagIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/joy';
import { Autocomplete, TextField } from '@mui/joy';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useCurrentOrganization } from '@/hooks/organization';

export default function CreateRoomContainer({ onCreate = () => {}, users, isDm }) {
  const router = useRouter();
  const [currentOrganization] = useCurrentOrganization();

  const [name, setName] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const { createRoom } = useRooms(currentOrganization);

  const [selectedValue, setSelectedValue] = React.useState('protected');
  const [selectedUserId, setSelectedUserId] = React.useState(null);

  const createNewRoom = React.useCallback(async () => {
    await createRoom({
      payload: {
        name: name,
        type: 'common',
        user_id: selectedUserId,
        visibility: selectedValue,
      },
      setIsCreating,
      setErrors,
      onSuccess: (room) => {
        router.push(`/rooms/${room.id}`);
        onCreate();
      },
    });
  }, [createRoom, name, selectedUserId, selectedValue, router, onCreate]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleInputChange = React.useCallback(
    (e) => {
      setName(e.target.value);

      // If we had a validation error, clear it after 1 second of typing, only if the field is not empty at the end of the timeout
      if (errors?.name) {
        setTimeout(() => {
          if (e.target.value.trim()) {
            setErrors({});
          }
        }, 1_000);
      }
    },
    [errors]
  );

  const handleInputKeyDown = React.useCallback(
    (e) => {
      if (e.key === 'Enter' && !name.trim()) {
        setErrors({ name: ['Give your room a name - any name!'] });
        return;
      }
      if (e.key === 'Enter') {
        createNewRoom();
      }
    },
    [name, createNewRoom]
  );

  return (
    <Box sx={{ minWidth: '320px' }}>
      <Stack
        gap={2}
        sx={{
          maxWidth: '600px',
        }}>
        <FormControl error={!!errors?.name}>
          <FormLabel>{isDm ? 'Start Messaging:' : 'Room Name'}</FormLabel>
          {isDm ? (
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Select User" variant="outlined" />
              )}
              sx={{ mb: 2 }}
              filterOptions={(options, state) =>
                options.filter((option) =>
                  option.name.toLowerCase().includes(state.inputValue.toLowerCase())
                )
              }
              onChange={(event, newValue) => {
                setSelectedUserId(newValue ? newValue.id : null);
              }}
            />
          ) : (
            <Input
              autoFocus
              startDecorator={<TagIcon />}
              placeholder="Marketing Break Room"
              value={name}
              required
              onChange={handleInputChange}
              error={errors?.name}
              onKeyDown={handleInputKeyDown}
            />
          )}
          {errors?.name && <FormHelperText>{errors.name[0]}</FormHelperText>}
        </FormControl>

        {!isDm && (
          <RadioGroup value={selectedValue} onChange={handleChange} sx={{ my: 1, gap: 2 }}>
            <FormControl>
              <Radio value="protected" label="Protected" />
              <FormHelperText>Can only be viewed or joined by invitation</FormHelperText>
            </FormControl>
            <FormControl>
              <Radio value="workspace" label="Organization" />
              <FormHelperText>
                Anyone in the Organization may discover, view, or join
              </FormHelperText>
            </FormControl>
          </RadioGroup>
        )}

        <FormControl>
          <Button
            disabled={isDm ? !selectedUserId : !name.trim()}
            loading={isCreating}
            onClick={createNewRoom}>
            Create
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
}
