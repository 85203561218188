import { Divider, Typography, Stack } from '@mui/joy';
import * as React from 'react';

export default function SectionDivider({ label, children }) {
  const isChildrenString = typeof children === 'string';

  return (
    <>
      <Divider
        sx={{
          mt: 1,
          mb: 2,
          mr: 0,
          fontSize: '12px',
          backgroundColor: '#e2e2e2',
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={children ? 'space-between' : 'flex-start'}
        sx={{ ml: 0.5 }}>
        <Typography
          level="body-xs"
          sx={{
            color: 'neutral.400',
          }}>
          {label}
        </Typography>
        {children}
      </Stack>
    </>
  );
}
