import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, FormControl, Input, Typography } from '@mui/joy';

import { Modal } from '@/components/reusable';

const StartMeetingModal = ({
  open,
  setOpen,
  conferenceUrl,
  setConferenceUrl,
  isCreating,
  onStartMeeting = () => {},
}) => {
  return (
    <Modal
      title="Add Groupthink to your Current Meeting"
      open={open}
      onClose={() => setOpen(false)}>
      <Typography level="body-lg">
        Paste your Meet, Zoom, or Teams meeting URL below and Groupthink will request to join.
        <Typography level="body-lg" fontWeight="lg">
          You must admit Groupthink to the meeting for the assistant to take notes.
        </Typography>
      </Typography>
      <FormControl required sx={{ my: 2 }}>
        <Typography fontWeight="lg">Meeting Link</Typography>
        <Input
          value={conferenceUrl}
          onChange={(e) => setConferenceUrl(e.target.value)}
          placeholder={'Meet, Zoom, or Teams URL'}
        />
      </FormControl>
      <Box alignItems="center" justifyContent="right" sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          loading={isCreating && conferenceUrl}
          disabled={isCreating || !conferenceUrl}
          onClick={() => onStartMeeting(conferenceUrl)}
          startDecorator={<AddIcon />}>
          Add Groupthink
        </Button>
      </Box>
    </Modal>
  );
};

export default StartMeetingModal;
