import { useRooms, useOrganizationUsers, useUser } from '@groupthinkai/groupthink';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TagIcon from '@mui/icons-material/Tag';
import { Link, MenuItem, Typography, Modal, ModalClose, Sheet, Divider, useTheme } from '@mui/joy';
import Box from '@mui/joy/Box';
import * as React from 'react';

import SectionDivider from '@/components/navigation/SectionDivider';
import SectionMenu from '@/components/navigation/SectionMenu';
import NavigationLink from '@/components/navigation/trees/NavigationLink';
import CreateRoomContainer from '@/components/room/CreateRoomContainer';
import JoinRoomContainer from '@/components/room/JoinRoomContainer';
import { useCurrentOrganization } from '@/hooks/organization';
import { usePusher } from '@/hooks/realtime';

export default function RoomTree({ activeLink }) {
  const [currentOrganization] = useCurrentOrganization();
  const { users } = useOrganizationUsers(currentOrganization);
  const { rooms, mutate } = useRooms(currentOrganization);
  const { user } = useUser('me');
  const [createOpen, setCreateOpen] = React.useState(false);
  const [browseOpen, setBrowseOpen] = React.useState(false);
  const [isDM, setIsDM] = React.useState(false);

  usePusher({
    type: 'MessageSent',
    channel: user ? `users.${user.id}` : null,
    callback: mutate,
  });

  const assistantRoom = rooms?.find((room) => room.type === 'assistant');
  const assistantRoomUrl = `/rooms/${assistantRoom?.id}`;
  const [title, setTitle] = React.useState('Create a New Room');

  const roomsWithoutAssistant = rooms?.filter((room) => room.type !== 'assistant');

  const theme = useTheme();

  const usersWithoutMe = users?.filter((u) => u.id !== user?.id);

  return (
    <>
      <SectionDivider label="Chat">
        <SectionMenu>
          <MenuItem
            onClick={() => {
              setCreateOpen(true);
              setIsDM(false); //JIC they clicked back and forth
            }}>
            New Room...
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCreateOpen(true);
              setIsDM(true);
              setTitle('New Direct Message');
            }}>
            New Direct Message...
          </MenuItem>
          <MenuItem onClick={() => setBrowseOpen(true)}>Join Existing Room...</MenuItem>
        </SectionMenu>
      </SectionDivider>
      <Box>
        {assistantRoom?.id && (
          <NavigationLink
            label={'Groupthink AI'}
            labelDecorator={<SmartToyIcon sx={{ fontSize: '1.25rem' }} />}
            active={activeLink === assistantRoomUrl}
            href={assistantRoomUrl}
          />
        )}

        {roomsWithoutAssistant?.map((room) => {
          const roomUrl = `/rooms/${room.id}`;

          const iconProps = {
            sx: {
              fontSize: '1.25rem',
              color: room.has_unread
                ? theme.palette.tertiary[500]
                : theme.palette.groupthinkNeutral[400],
            },
          };

          return (
            <NavigationLink
              key={room.id}
              label={room.name}
              labelDecorator={
                room?.assistant ? (
                  <SmartToyIcon {...iconProps} />
                ) : room?.visibility === 'workspace' ? (
                  <TagIcon {...iconProps} />
                ) : room?.visibility === 'public' ? (
                  <PublicOutlinedIcon {...iconProps} />
                ) : (
                  <LockOutlinedIcon {...iconProps} />
                )
              }
              active={activeLink === roomUrl}
              bold={room.has_unread}
              href={roomUrl}
            />
          );
        })}
      </Box>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined" sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="body-lg"
            textColor="inherit"
            sx={{ fontWeight: 'lg', mb: 1 }}>
            {title}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <CreateRoomContainer
            onCreate={() => setCreateOpen(false)}
            users={usersWithoutMe}
            isDm={isDM}
          />
        </Sheet>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={browseOpen}
        onClose={() => setBrowseOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet
          variant="outlined"
          sx={{ borderRadius: 'md', p: 3, boxShadow: 'lg', width: '600px' }}>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="body-lg"
            textColor="inherit"
            sx={{ fontWeight: 'lg', mb: 1 }}>
            Your Organization&apos;s Rooms
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <JoinRoomContainer onCreate={() => setBrowseOpen(false)} />
        </Sheet>
      </Modal>
    </>
  );
}
