import { useDiscoverableRooms, useRooms, useUser } from '@groupthinkai/groupthink';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import TagIcon from '@mui/icons-material/Tag';
import { CircularProgress, List, ListDivider, ListItem, ListItemDecorator, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useCurrentOrganization } from '@/hooks/organization';

export default function JoinRoomContainer({ onCreate = () => {} }) {
  const router = useRouter();
  const { user } = useUser('me');
  const [currentOrganization] = useCurrentOrganization();
  const [name, setName] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);
  const { mutate } = useRooms(currentOrganization);
  const { rooms, joinRoom, isLoading } = useDiscoverableRooms(currentOrganization);

  const join = async (id) => {
    await joinRoom(id, {
      setIsCreating,
      onSuccess: (room) => {
        mutate();
        router.push(`/rooms/${id}`);
        onCreate();
      },
    });
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography level="body-lg">Loading...</Typography>
      </Stack>
    );
  }

  return (
    <Box>
      <List variant="outlined">
        {rooms?.map(function (room) {
          const currentUserIsMember = room.users?.find((member) => member.id === user.id);
          return (
            <>
              <ListItem
                key={room.id}
                endAction={
                  <Button
                    onClick={() => join(room.id)}
                    disabled={currentUserIsMember}
                    size={'sm'}
                    color={currentUserIsMember ? 'neutral' : 'tertiary'}>
                    {currentUserIsMember ? 'Joined' : 'Join'}
                  </Button>
                }>
                <ListItemDecorator>
                  {room?.visibility === 'workspace' ? (
                    <TagIcon />
                  ) : room?.visibility === 'public' ? (
                    <PublicOutlinedIcon />
                  ) : (
                    <LockOutlinedIcon />
                  )}
                </ListItemDecorator>
                <Typography level="body-md">{room.name}</Typography>
              </ListItem>
              <ListDivider />
            </>
          );
        })}
      </List>
    </Box>
  );
}
