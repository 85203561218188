import {
  useAgenda,
  selectAgendaHost,
  selectNextMeetingTime,
  selectPreviousMeetingTime,
} from '@groupthinkai/groupthink';
import {
  CalendarTodayOutlined as CalendarTodayIcon,
  EditCalendarOutlined as EditCalendarIcon,
  GroupOutlined as GroupIcon,
  PersonOutlined as PersonIcon,
} from '@mui/icons-material';
import { Box, Link as FormattedLink, Sheet, Stack, Tooltip, Typography, useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import React, { useCallback } from 'react';

import MeetingTimer from '@/components/agenda/MeetingTimer';
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(localizedFormat);

export default function AgendaCard({
  agenda,
  displayPreviousMeeting = false,
  faint = false,
  list = false,
  verbose = false,
  link = true,
}) {
  const meetingUrl = `/agendas/${agenda.id}`;

  let { time_string, meeting_imminent, meeting_is_scheduled, meeting_has_no_upcoming_event } =
    selectNextMeetingTime(agenda);

  let { time_string_started, time_string_ended, meeting_just_ended, has_no_previous_meeting } =
    selectPreviousMeetingTime(agenda);

  const theme = useTheme();

  const mdPlus = useMediaQuery(theme.breakpoints.up('md'));

  const renderNextMeetingTime = useCallback(() => {
    // Case: Active meeting
    if (agenda?.active_meeting?.started_at || agenda?.active_meeting?.ends_at) {
      return (
        <Typography
          level={'body-sm'}
          title="Meeting is ongoing"
          startDecorator={<CalendarTodayIcon fontSize="small" color="info" />}>
          <MeetingTimer
            startTime={agenda?.active_meeting?.started_at}
            endTime={agenda?.active_meeting?.ends_at}
            verbose={false}
          />
        </Typography>
      );
    }

    // Case: Meeting is imminent or scheduled
    if (meeting_imminent || meeting_is_scheduled) {
      return (
        <Typography
          level={'body-sm'}
          title="Meeting time"
          startDecorator={
            !list && time_string && <CalendarTodayIcon fontSize="small" sx={{ color: '#777777' }} />
          }>
          {time_string}
        </Typography>
      );
    }

    // Case: No upcoming event
    if (meeting_has_no_upcoming_event) {
      return null;
    }

    return null;
  }, [
    agenda?.active_meeting,
    meeting_imminent,
    meeting_is_scheduled,
    meeting_has_no_upcoming_event,
    time_string,
    list,
  ]);

  const renderPreviousMeetingTime = useCallback(() => {
    if (has_no_previous_meeting) {
      // Case: No previous meeting
      return null;
    }

    if (agenda?.active_meeting?.started_at || agenda?.active_meeting?.ends_at) {
      // Case: Active meeting, don't show previous meeting info
      return null;
    }

    // Case: Meeting is imminent, don't show previous meeting info
    if (meeting_imminent) {
      return null;
    }

    return (
      <Typography
        level={'body-sm'}
        title="Meeting started time"
        startDecorator={
          !list &&
          time_string_started && <CalendarTodayIcon fontSize="small" sx={{ color: '#777777' }} />
        }>
        {time_string_started}
      </Typography>
    );
  }, [
    agenda?.active_meeting,
    time_string_ended,
    time_string_started,
    has_no_previous_meeting,
    meeting_just_ended,
    meeting_imminent,
    list,
  ]);

  const host = selectAgendaHost(agenda);

  const linkProps = link
    ? {
        component: Link,
        href: meetingUrl,
      }
    : {
        component: 'div',
      };

  const isAgendaDraft = !agenda?.attached_event && !agenda?.active_meeting && !agenda?.next_meeting;

  return (
    <FormattedLink
      {...linkProps}
      underline="none"
      sx={{
        color: 'text.tertiary',
        flexGrow: 1,
        justifyContent: 'center',
        width: '100%',
      }}>
      <Sheet
        sx={(theme) => ({
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          '&:hover': {
            backgroundColor: theme.palette.tertiary[50],
          },
        })}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderLeft: `${agenda?.active_meeting ? 'solid #1F97AC 0.5rem' : null}`,
            ...(agenda?.active_meeting ? { paddingLeft: 3 } : {}),
          }}>
          <Stack
            spacing={1}
            direction={list && mdPlus ? 'row' : 'column'}
            justifyContent={'space-between'}
            sx={{ opacity: faint ? 0.25 : 1 }}>
            <Typography level={isAgendaDraft && !verbose ? 'title-sm' : 'title-md'}>
              {agenda?.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}>
              {!displayPreviousMeeting && renderNextMeetingTime()}
              {displayPreviousMeeting && renderPreviousMeetingTime()}
              {!list && agenda?.users?.length && (
                <Tooltip
                  title={`${agenda?.users?.length} attendee${
                    agenda?.users?.length > 1 ? 's' : ''
                  }`}>
                  <Typography
                    level={'body-sm'}
                    title="Number of attendees"
                    startDecorator={<GroupIcon sx={{ color: '#777777' }} />}>
                    {agenda?.users?.length}
                  </Typography>
                </Tooltip>
              )}
              {!list && host?.name && (
                <Tooltip title={`Hosted by ${host.name}`}>
                  <Typography
                    level={'body-sm'}
                    title="Meeting host"
                    startDecorator={<PersonIcon sx={{ color: '#777777' }} />}>
                    {host.name}
                  </Typography>
                </Tooltip>
              )}
              {isAgendaDraft && agenda?.created_at && (
                <Tooltip
                  title={`Draft agenda created at ${dayjs(agenda?.created_at).format('LL, LT')}`}>
                  <Typography
                    level={'body-sm'}
                    startDecorator={<EditCalendarIcon sx={{ color: '#777777' }} />}
                    textAlign={'right'}>
                    {dayjs(agenda?.created_at).from(dayjs())}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Stack>
        </Box>
      </Sheet>
    </FormattedLink>
  );
}

export const AgendaCardById = ({ agendaId }) => {
  const { agenda } = useAgenda(agendaId);
  return agenda ? <AgendaCard agenda={agenda} /> : null;
};
