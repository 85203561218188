import { Link, Typography } from '@mui/joy';
import * as NextLink from 'next/link';
import * as React from 'react';

// Defaulting to using neutral colors in order to match SimpleItemTree's TreeItem styling.
const USE_PRIMARY_COLOR_IF_ACTIVE = false;

/**
 * This is the component used for Navigation items that are not SimpleTreeView items.
 */
export default function NavigationLink({
  active,
  label,
  labelDecorator,
  href,
  target,
  bold = false,
}) {
  return (
    <Link
      component={NextLink}
      href={href}
      target={target || '_self'}
      className="labeledLink"
      sx={{
        display: 'flex',
        backgroundColor: active ? 'groupthinkNeutral.200' : 'transparent',
        flexDirection: 'row',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: 'groupthinkNeutral.200',
        },
        width: '100%',
        p: 0.5,
        my: 0.5,
        borderRadius: 4,
      }}>
      <Typography
        startDecorator={labelDecorator}
        level="body-sm"
        className="navigation-label"
        color={`${active && USE_PRIMARY_COLOR_IF_ACTIVE ? 'primary' : 'neutral'}`}
        sx={{
          fontWeight: bold ? 'bold' : 'normal',
        }}
        slotProps={{
          startDecorator: {
            sx: {
              width: '20px',
            },
          },
        }}>
        {label}
      </Typography>
    </Link>
  );
}
