import { useAgenda, useCalendarEvents, useOrganization } from '@groupthinkai/groupthink';
import {
  Box,
  Button,
  FormControl,
  Select,
  Input,
  FormLabel,
  Autocomplete,
  Stack,
  Option,
  Checkbox,
  FormHelperText,
  Textarea,
  Typography,
} from '@mui/joy';
import { Snackbar, Link } from '@mui/joy';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as chrono from 'chrono-node';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as React from 'react';

import AgendaSelect from '../agenda/AgendaSelect';

import { Modal } from '@/components/reusable';
import { useCurrentOrganization } from '@/hooks/organization';

const TIME_FORMAT = 'h:mm A';

const ScheduleMeetingModal = ({ open, setOpen, onCreateMeeting, agendaId }) => {
  const router = useRouter();
  const selectedDate = router.query.date ? dayjs(router.query.date) : dayjs();

  const [currentOrganizationId] = useCurrentOrganization();
  const { organization } = useOrganization(currentOrganizationId);
  const { mutateList, createEvent } = useCalendarEvents(
    organization?.id,
    organization?.calendar_id,
    selectedDate?.format('YYYY-MM-DD') ?? ''
  );

  const [attendees, setAttendees] = useState([]);
  const [attendeesInputValue, setAttendeesInputValue] = useState('');
  const [description, setDescription] = useState('');
  const [didSave, setDidSave] = useState(false);
  const [errors, setErrors] = useState({});
  const [isCreating, setIsCreating] = React.useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState('weekly');
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [showTitleSuggestion, setShowTitleSuggestion] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [title, setTitle] = useState('');
  const [titleSuggestion, setTitleSuggestion] = useState(null);
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);

  const closestFifteenMinuteTimeToNow = startDate.minute(Math.ceil(dayjs().minute() / 15) * 15);
  const thirtyMinutesFromClosestTime = closestFifteenMinuteTimeToNow.add(30, 'minutes');
  const [startTime, setStartTime] = useState(closestFifteenMinuteTimeToNow);
  const [endTime, setEndTime] = useState(thirtyMinutesFromClosestTime.startOf('minute'));

  const { agenda } = useAgenda(selectedAgendaId);

  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');

  const meeting_length_minutes =
    startTime && endTime
      ? dayjs(endTime, TIME_FORMAT).diff(dayjs(startTime, TIME_FORMAT).startOf('minute'), 'minute')
      : -1;

  useEffect(() => {
    if (agendaId) {
      setSelectedAgendaId(agendaId);
    }
  }, [agendaId]);

  // suggest we use the selected agenda name for title
  useEffect(() => {
    agenda?.name && setTitleSuggestion(agenda.name);
  }, [agenda?.id]);

  // NLP date parsing for title field
  useEffect(() => {
    if (title) {
      const parsedDate = chrono.parse(title, new Date(), { forwardDate: true });

      if (parsedDate.length > 0) {
        const dateInfo = parsedDate[0];
        const updatedTitle = title.replace(dateInfo.text, '').trim();

        setTitleSuggestion(updatedTitle);

        if (dateInfo.start) {
          const startDateTime = dayjs(dateInfo.start.date());
          setStartDate(startDateTime);
          setStartTime(startDateTime);

          if (dateInfo.end) {
            setEndTime(dayjs(dateInfo.end.date()));
          } else {
            setEndTime(startDateTime.add(30, 'minutes'));
          }
        }
      }
    }
  }, [title]);

  // the errors object can have multiple errors for array and object fields, so for
  // the attendees field, we may have a key for attendees.0, or attendees.2, or anything else
  // we're going to loop through the keys and group the errors by the field name expanded into a sub-array
  useEffect(() => {
    if (errors) {
      let newErrors = {};
      Object.keys(errors).forEach((key) => {
        const [field, index] = key.split('.');
        if (index) {
          if (!newErrors[field]) {
            newErrors[field] = [];
          }
          newErrors[field].push(errors[key]);
        } else {
          newErrors[field] = errors[key];
        }
      });
      setErrors(newErrors);
    }
  }, [JSON.stringify(errors)]);

  useEffect(() => {
    // if the startTime changes, and the endTime is before the startTime, update the endTime to be 30 mins after the new startTime
    if (dayjs(endTime, TIME_FORMAT).isBefore(dayjs(startTime, TIME_FORMAT))) {
      setEndTime(dayjs(startTime, TIME_FORMAT).add(30, 'minutes'));
    }
  }, [startTime, endTime]);

  const handleCreateMeeting = () => {
    let payload = {
      title: title,
      description: description,
      attendees: attendees,
      starts_at: dayjs(startDate)
        .hour(startTime.hour())
        .minute(startTime.minute())
        .second(0)
        .millisecond(0),
      ends_at: dayjs(startDate)
        .hour(endTime.hour())
        .minute(endTime.minute())
        .second(0)
        .millisecond(0),
    };

    if (selectedAgendaId) {
      payload.agenda_id = selectedAgendaId;
    }

    if (isRecurring) {
      payload.recurrence = {
        interval: recurrenceInterval,
        frequency: recurrenceFrequency,
      };
    }

    createEvent({
      payload: payload,
      setIsCreating,
      setErrors,
      onSuccess: (data) => {
        mutateList();
        setOpen(false);
        setDidSave(true);
        onCreateMeeting && onCreateMeeting(data);
        // finally clear all of the payload fields from state so the modal is fresh for the next time it's opened
        setTitle('');
        setDescription('');
        setAttendees([]);
        setStartDate(dayjs());
        setStartTime(closestFifteenMinuteTimeToNow);
        setEndTime(thirtyMinutesFromClosestTime.startOf('minute'));
        setIsRecurring(false);
        setRecurrenceInterval(1);
        setRecurrenceFrequency('weekly');
        if (data.agenda_id) {
          router.push(`/agendas/${data.agenda_id}?shared=true`);
        }
      },
    });
  };

  const handleTitleChange = () => {
    setTitle(titleSuggestion);
  };

  const handleAgendaChange = React.useCallback(
    (newAgendaId) => {
      setSelectedAgendaId(newAgendaId);
    },
    [setSelectedAgendaId]
  );

  useEffect(() => {
    console.log('Start time changed:', startTime.format('HH:mm:ss'));
  }, [startTime]);

  const recurrenceFrequencyLabels = {
    daily: 'Day' + (recurrenceInterval > 1 ? 's' : ''),
    weekly: 'Week' + (recurrenceInterval > 1 ? 's' : ''),
    monthly: 'Month' + (recurrenceInterval > 1 ? 's' : ''),
    annually: 'Year' + (recurrenceInterval > 1 ? 's' : ''),
  };

  return (
    <>
      <Modal
        title="Schedule a Groupthink Meeting"
        description={'Create a new event on your calendar with Groupthink video'}
        open={open}
        onClose={() => setOpen(false)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl error={Boolean(errors?.title)} required sx={{ my: 2 }}>
            <FormLabel>Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={'My Groupthink Meeting at 2pm Thursday'}
            />
            {titleSuggestion && title !== titleSuggestion && (
              <Typography level="body-sm" sx={{ mt: 1 }}>
                <Link color="neutral" onClick={handleTitleChange}>
                  Change title to: {titleSuggestion}
                </Link>
              </Typography>
            )}
            {errors.title && (
              <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                {errors.title.join(' ')}
              </FormHelperText>
            )}
          </FormControl>
          <Stack direction="row" spacing={2} sx={{ my: 2 }}>
            <FormControl required sx={{ my: 2, display: 'flex', flex: 2 }}>
              <DatePicker
                label={'Event Date'}
                disablePast={true}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                format={'dddd, MMMM D'}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      input: {
                        py: '0.4375rem',
                      },
                    },
                  },
                }}
              />
            </FormControl>
            <FormControl required sx={{ my: 2, flex: 1, minWidth: 0 }}>
              <TimePicker
                label="Event Time"
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                format={TIME_FORMAT}
                timeSteps={{ minutes: 15 }}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      input: {
                        py: '0.4375rem',
                      },
                    },
                  },
                }}
              />
            </FormControl>
            {/*<FormControl required sx={{ my: 2, flex: 2 }}>*/}
            {/*  <DateTimePicker*/}
            {/*    disablePast={true}*/}
            {/*    value={endDate}*/}
            {/*    onChange={(newValue) => setEndDate(newValue)}*/}
            {/*    slotProps={{*/}
            {/*      textField: {*/}
            {/*        size: 'small',*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</FormControl>*/}
            <FormControl required sx={{ my: 2, display: 'flex', flex: 1, minWidth: 0 }}>
              <TimePicker
                label="End Time"
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
                format={TIME_FORMAT}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      input: {
                        py: '0.4375rem',
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent={'space-between'} spacing={2} sx={{ my: 2 }}>
            <Box sx={{ my: 2 }}>
              <FormControl>
                <Checkbox
                  overlay
                  size="sm"
                  checked={isRecurring}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsRecurring(true);
                    } else {
                      setIsRecurring(false);
                    }
                  }}
                  label={'This Event Repeats'}
                />
              </FormControl>
              {isRecurring && (
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                  <FormControl
                    required
                    error={Boolean(errors['recurrence'])}
                    sx={{ my: 2, flex: 1, minWidth: 0 }}>
                    <FormLabel>Repeats Every</FormLabel>
                    <Input
                      type="number"
                      value={recurrenceInterval}
                      onChange={(e) => setRecurrenceInterval(e.target.value)}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                    {errors['recurrence'] && (
                      <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                        {errors['recurrence'].join(' ')}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl required sx={{ pt: 3.25, mb: 2, flex: 1 }}>
                    <Select
                      value={recurrenceFrequency}
                      renderValue={() => recurrenceFrequencyLabels[recurrenceFrequency]}
                      onChange={(e, value) => setRecurrenceFrequency(value)}>
                      <Option value={'daily'}>{recurrenceFrequencyLabels['daily']}</Option>
                      <Option value={'weekly'}>{recurrenceFrequencyLabels['weekly']}</Option>
                      <Option value={'monthly'}>{recurrenceFrequencyLabels['monthly']}</Option>
                      <Option value={'annually'}>{recurrenceFrequencyLabels['annually']}</Option>
                    </Select>
                  </FormControl>
                  {/*<FormControl required sx={{ my: 2, flexGrow: 1 }}>*/}
                  {/*  <FormLabel>Until</FormLabel>*/}
                  {/*  <DatePicker*/}
                  {/*    value={recurrenceEndDate}*/}
                  {/*    slotProps={{*/}
                  {/*      textField: {*/}
                  {/*        size: 'small',*/}
                  {/*      },*/}
                  {/*    }}*/}
                  {/*    onChange={(date) => setRecurrenceEndDate(date)}*/}
                  {/*    disablePast={true}*/}
                  {/*  />*/}
                  {/*</FormControl>*/}
                </Stack>
              )}
            </Box>
            <Box>
              {meeting_length_minutes > 1 && (
                <Typography level="body-sm">{meeting_length_minutes} minutes</Typography>
              )}
            </Box>
          </Stack>

          <FormControl sx={{ my: 2 }}>
            <FormLabel>Attendees</FormLabel>
            <Autocomplete
              value={attendees}
              options={[]}
              onChange={(event, newValue) => {
                // test the last value to see if it's an email
                if (emailRegex.test(newValue[newValue.length - 1]) || newValue.length === 0) {
                  setAttendees(newValue);
                }
              }}
              onBlur={() => {
                // Add attendees onBlur if the input value is an email address
                const potentialEmail = attendeesInputValue.replace(/[, ]/g, '');

                if (emailRegex.test(potentialEmail)) {
                  setAttendees([...attendees, potentialEmail]);

                  // only clear the input if it's a valid email address
                  setAttendeesInputValue('');
                }
              }}
              multiple
              freeSolo
              placeholder="Email Addresses"
              onInputChange={(event, newInputValue) => {
                // This function handles commas and spaces the same way autocomplete natively handles the enter button
                // if the current string ends in a comma or space
                const lastCharacter = newInputValue[newInputValue.length - 1];
                if (lastCharacter === ',' || lastCharacter === ' ') {
                  // strip commas and spaces
                  const potentialEmail = newInputValue.replace(/[, ]/g, '');

                  // if it's an email address, add it to the list
                  if (emailRegex.test(potentialEmail)) {
                    setAttendees([...attendees, potentialEmail]);
                  }

                  // either way, clear the input
                  setAttendeesInputValue('');
                } else {
                  // otherwise, update the input value
                  setAttendeesInputValue(newInputValue);
                }
              }}
              inputValue={attendeesInputValue}
            />

            {errors['attendees'] && (
              <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                {errors['attendees'].join(' ')}
              </FormHelperText>
            )}
            <FormHelperText>Press &quot;enter&quot; after each valid email address</FormHelperText>
          </FormControl>

          <FormControl sx={{ my: 2 }}>
            <FormLabel>Description</FormLabel>
            <Textarea
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={'Why are we having this meeting?'}
            />
          </FormControl>

          <FormControl sx={{ my: 2 }}>
            <FormLabel>Agenda</FormLabel>
            <AgendaSelect value={selectedAgendaId} onChange={handleAgendaChange} />
          </FormControl>

          <Box
            alignItems="center"
            justifyContent="space-between"
            sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Typography level="body-sm">
              Groupthink will provide video conferencing, note-taking, and agenda
            </Typography>
            <Button loading={isCreating} disabled={isCreating} onClick={handleCreateMeeting}>
              Create Event
            </Button>
          </Box>
        </LocalizationProvider>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        variant="solid"
        color="primary"
        open={didSave}
        autoHideDuration={6000}
        onClose={() => setDidSave(false)}>
        Event Created! Hang on while we sync your calendar...
      </Snackbar>
    </>
  );
};

export default ScheduleMeetingModal;
